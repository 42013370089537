<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">巡检看板详情</span>
		</div>
		<el-card class="el-main">
            <el-tabs v-model="tabAc" >
                <el-tab-pane label="巡检评率" name="xjpl">
                    <div class="a-flex-rfsc">
                        <div class="a-flex-ccc complaint-item a-c-white" v-for="(value,key,index) in patrolFrequencyDic" :key="index" :style="statusStyle[key]">
                            <span class="title" :style="statusColorStyle[key]">{{ value }}</span>
                            <div class="waitNum" :style="statusColorStyle[key]">
                                <span>{{ patrolFrequencyInfo[key] }}</span>
                                <span v-if="key != 'total'">（{{ patrolFrequencyInfo[key]?(patrolFrequencyInfo[key] / patrolFrequencyInfo['total'] * 100).toFixed(2):0 }}%）</span>
                            </div>
                        </div>
                    </div>
                    <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                        <le-select-local-search label="不看从未巡检" v-model="pageParam.params.banNotPatrol" :clearable='false' :options="optionsBanNotPatrol" />
                        <le-input label="距上次巡检大于(天)" @input="pageParam.params.day=pageParam.params.day.replace(/^(0+)|[^\d]+/g,'')" v-model="pageParam.params.day" />
                        <le-input-district-choose
                            label='省市区'
                            ref="districtChoose"
                            :province.sync="pageParam.params.provinceCode" 
                            :city.sync="pageParam.params.cityCode"
                            :area.sync='pageParam.params.districtCode'></le-input-district-choose>
                    </le-search-form>
                    <le-pagview ref="inspectionDataPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.inspectionDataList">
                        <el-table ref="inspectionDataList" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                            <el-table-column prop="stationId" label="站点ID" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ row.stationId || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderId" label="站点名称" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ row.stationName || "" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderId" label="距上次巡检" min-width="120">
                                <template slot-scope="{ row }">
                                    <span v-if="row.days || row.days === 0 || row.days === '0'">{{ row.days || "-" }}天</span>
                                    <span class="a-c-error" v-else>从未巡检</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <template slot="footerLabel" slot-scope="scope" >
                            <div class="a-flex-rfsc" v-if="scope.val != -1">
                                <span class="a-fs-12 a-c-normal">共</span>
                                <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                                <span class="a-fs-12 a-c-normal">条记录</span>
                            </div>
                        </template>
                    </le-pagview>
                </el-tab-pane>
                <el-tab-pane label="异常站点" name="yczd">
                    <le-search-form @reset="handlerRestError" @search="handlerSearchError" :canPackUp="true" id="leSearch">
                        <le-select-local-search label="排序" v-model="pageParamError.params.sort" :options="optionsSort" />
                        <le-input label="巡检异常超过(次)" v-model="pageParamError.params.errorCount" />
                        <le-input label="异常占比超过(%)" v-model="ratio" @input="ratio = util.checkMoney(ratio)" />
                        <le-input-district-choose
                            label='省市区'
                            :province.sync="pageParamError.params.provinceCode" 
                            :city.sync="pageParamError.params.cityCode"
                            :area.sync='pageParamError.params.districtCode'></le-input-district-choose>
                    </le-search-form>
                    <le-pagview ref="inspectionErrorPage" @setData="setTableDataError" :pageParam="pageParamError" :isFixed="true" :tableRef="$refs.inspectionErrorList">
                        <el-table ref="inspectionErrorList" :data="tableDataError" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                            <el-table-column prop="stationId" label="站点ID" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ row.stationId || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderId" label="站点名称" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ row.stationName || "" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderId" label="异常次数/总次数" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ row.errorTotal || "0" }}/{{ row.total || "0" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderId" label="异常占比" min-width="120">
                                <template slot-scope="{ row }">
                                    <span>{{ (row.ratio * 100).toFixed(2) }}%</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <template slot="footerLabel" slot-scope="scope" >
                            <div class="a-flex-rfsc" v-if="scope.val != -1">
                                <span class="a-fs-12 a-c-normal">共</span>
                                <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                                <span class="a-fs-12 a-c-normal">条记录</span>
                            </div>
                        </template>
                    </le-pagview>
                </el-tab-pane>
            </el-tabs>
            
		</el-card>
	</div>
</template>

<script>
let _this
import util from "../../utils/util.js";
import { mapState } from 'vuex';
_this
export default {
	data() {
		return {
            tabAc: 'xjpl',
			util: util,
            ratio: "",
            companyId: '',
            provinceCode: '',
            cityCode: '',
            districtCode: '',
			tableData: [],
            tableDataError: [],
			pageParam: {
				url: this.$Config.apiUrl.patrolRecord,
				method: "post",
				params: {
					banNotPatrol: 1,
                    day: "",
                    cityCode: "",
                    companyId: "",
                    districtCode: "",
                    provinceCode: ""
				},
                freshCtrl: 1,
			},
            pageParamError: {
				url: this.$Config.apiUrl.errorRecord,
				method: "post",
				params: {
					sort: 1,
                    errorCount: '3',
                    ratio: "",
                    cityCode: "",
                    companyId: "",
                    districtCode: "",
                    provinceCode: ""
				},
                freshCtrl: 1,
			},
            optionsBanNotPatrol: [{
                label: '是',
                value: 1
            },{
                label: '否',
                value: 0
            }],
            optionsSort: [{
                label: '异常次数从大到小',
                value: 1
            },{
                label: '异常比例从大到小',
                value: 2
            }],
            patrolFrequencyDic: {
                total: "选定区域包含站点",
                day30NotPatrol: '超30天未巡检站点',
                day60NotPatrol: "超60天未巡检站点",
                hisNotPatrol: "从未巡检站点",
                lastMonthPatrol: "上月已巡检站点",
                monthPatrol: "本月已巡检站点",
            },
            patrolFrequencyInfo: {
                day30NotPatrol: 0,
                day60NotPatrol: 0,
                hisNotPatrol: 0,
                lastMonthPatrol: 0,
                monthPatrol:0,
                total: 0,
            },
            statusStyle: {
                day30NotPatrol: 'background: #E6A23C40',
                day60NotPatrol: 'background: #007af040',
                hisNotPatrol: 'background: #67C23A40',
                lastMonthPatrol: 'background: #F56C6C40',
                monthPatrol: 'background: #FF484840',
                total: 'background: #24d4d040',
            },
            statusColorStyle: {
                day30NotPatrol: 'color: #E6A23C',
                day60NotPatrol: 'color: #007af0',
                hisNotPatrol: 'color: #67C23A',
                lastMonthPatrol: 'color: #F56C6C',
                monthPatrol: 'color: #FF4848',
                total: 'color: #24d4d0',
            }
		}
	},
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.pageParam.params.provinceCode = this.$route.query.provinceCode
        this.pageParam.params.cityCode = this.$route.query.cityCode
        this.pageParam.params.districtCode = this.$route.query.districtCode
        this.pageParam.params.companyId = this.$route.query.companyId

        this.pageParamError.params.provinceCode = this.$route.query.provinceCode
        this.pageParamError.params.cityCode = this.$route.query.cityCode
        this.pageParamError.params.districtCode = this.$route.query.districtCode
        this.pageParamError.params.companyId = this.$route.query.companyId

        this.provinceCode = this.$route.query.provinceCode
        this.cityCode = this.$route.query.cityCode
        this.districtCode = this.$route.query.districtCode
        this.companyId = this.$route.query.companyId

        this.$nextTick(()=>{
            if(this.pageParam.params.provinceCode){
                this.$refs['districtChoose'].values = [this.pageParam.params.provinceCode]
            }
            if(this.pageParam.params.cityCode){
                this.$refs['districtChoose'].values = [this.pageParam.params.provinceCode, this.pageParam.params.cityCode]
            }
            if(this.pageParam.params.districtCode){
                this.$refs['districtChoose'].values = [this.pageParam.params.provinceCode, this.pageParam.params.cityCode, this.pageParam.params.districtCode]
            }
        })
    },
    filters: {
        initInspectionTime (row) {
            let time1 = _this.$getDay.getTimeUnix(row.sendTime)
            let time2 = _this.$getDay.getTimeUnix(row.endTime)
            let diff = time2 - time1
            if(diff <= 0) {
                return '-'
            }else if (diff >= (48 * 60 * 60 * 1000)) {
                return Math.ceil(diff / (24 * 60 * 60 * 1000)) + '天'
            }else {
                let hour = parseInt(diff / (60 * 60 * 1000))
				let minute = diff % (60 * 60 * 1000)
                minute = parseInt(minute / (60 * 1000))
				return (hour?hour + '小时':'') + (minute?minute:0) + '分钟'
            }
        }
    },
	mounted() { 
        this.getPatrolFrequency()
    },
	methods: {
        getPatrolFrequency () {
            this.$Axios._post({
                url: this.$Config.apiUrl.patrolFrequency,
                method: "post",
                showLoading: false,
                params: {
                    startDate: '2024-08-01',
                    companyId: this.companyId,
                    provinceCode: this.provinceCode,
                    cityCode: this.cityCode,
                    districtCode: this.districtCode
                }
            }).then(({data}) => {
                this.patrolFrequencyInfo = Object.assign(this.patrolFrequencyInfo, data)
            })
        },
		setTableData(data) {
			this.tableData = data;
		},
        handlerRest() {
			this.pageParam.params = {
				banNotPatrol: 1,
                day: "",
                companyId: this.companyId,
                provinceCode: this.provinceCode,
                cityCode: this.cityCode,
                districtCode: this.districtCode
			};
			this.handlerSearch()
		},
		handlerSearch() {
			this.$refs['inspectionDataPage'].pageNum = 1
			this.pageParam.freshCtrl++;
		},
        setTableDataError(data) {
			this.tableDataError = data;
		},
        handlerRestError() {
			this.pageParamError.params = {
				sort: 1,
                errorCount: '3',
                ratio: "",
                companyId: this.companyId,
                provinceCode: this.provinceCode,
                cityCode: this.cityCode,
                districtCode: this.districtCode
			};
            this.ratio = ""
			this.handlerSearchError()
		},
		handlerSearchError() {
			this.$refs['inspectionErrorPage'].pageNum = 1
            this.pageParamError.params.ratio = this.ratio ? (this.ratio * 100).toFixed(0) : ''
			this.pageParamError.freshCtrl++;
		},
	},
};
</script>

<style lang="scss" scoped>
    .s-btn-add{
        width: 105px;
    }
    /deep/ .s-search-label {
        width: 130px !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
        height: 100% !important;
    }
    .complaint-item{
        margin-right: 20px;
        height: 48px;
        border-radius: 8px;
        padding: 0 10px;
        .title{
            font-size: 14px;
            font-weight: 500;
        }
        .waitNum{
            font-size: 16px;
            font-weight: 700;
            margin-top: 6px;
            // color: #007AFF;
        }
        .waitText{
            font-size: 16px;
            font-weight: 400;
            // color: #007AFF;
        }
        .overtimeText{
            font-size: 14px;
            color: #666666;
        }
        .overtimeNum{
            font-size: 20px;
            color: #F56C6C;
        }
    }
    /deep/ .el-tabs__item {
        // color: #007aff;
        font-size: 14px;
    }

    /deep/ .el-tabs__item.is-active {
        color: #007AFF;
        font-weight: bold;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background: none !important;
    }
</style>
